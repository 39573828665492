/* eslint-disable @next/next/no-img-element */
import usePrefix from '@/hooks/usePrefix';
import { Button, Divider, Layout } from 'antd';
import dayjs from 'dayjs';
import { DownOutlined, UpOutlined } from '@/components2/Icons';
import { ReactElement, useEffect, useState } from 'react';
import Image from 'next/image';
import { CDN2 } from '@/helpers/api';
import ButtonStore from '@/components/ButtonStore';
import ButtonSocial from '@/components/ButtonSocial';

const { Footer } = Layout;

export default function AntFooter(): ReactElement {
  const [isMobile, setIsMobile] = useState(false);

  function resizeMobile() {
    setIsMobile(window.innerWidth < 768);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeMobile);
    setIsMobile(window.innerWidth < 768);
    return () => {
      window.removeEventListener('resize', resizeMobile);
    };
  }, []);
  useEffect(() => {
    if (isMobile) {
      setShowItemsFooterOne(false);
      setShowItemsFooterTwo(false);
      setShowItemsFooterThree(false);
      setShowItemsFooterFour(false);
    } else {
      setShowItemsFooterOne(true);
      setShowItemsFooterTwo(true);
      setShowItemsFooterThree(true);
      setShowItemsFooterFour(true);
    }
  }, [isMobile]);

  const [showItemsFooterOne, setShowItemsFooterOne] = useState(false);
  const [showItemsFooterTwo, setShowItemsFooterTwo] = useState(false);
  const [showItemsFooterThree, setShowItemsFooterThree] = useState(false);
  const [showItemsFooterFour, setShowItemsFooterFour] = useState(false);

  const prefix = usePrefix();
  return (
    <div className="w-full self-center">
      <Footer className="flex flex-col !p-0 ant-layout-footer-2 pt-8">
        <div className="flex flex-col justify-center md:-mx-8 md:flex-row gap-y-3 md:gap-32 mt-3 md:mt-6 mb-6 md:mb-0 w-full h-auto !py-0 !pr-6 !pl-8">
          <div className="col-span-4">
            <div className="flex flex-row text-center">
              <p className="pt-2 lg:py-0 text-left text-sm font-bold md:text-left w-full md:max-w-xl">
                Park with On Air Parking
              </p>
              <Button
                size="large"
                className="flex md:hidden border-none bg-transparent text-primary shadow-transparent hover:bg-transparent "
                onClick={() => setShowItemsFooterOne(!showItemsFooterOne)}
              >
                {showItemsFooterOne ? <UpOutlined /> : <DownOutlined />}
              </Button>
            </div>
            <ul
              className={`flex flex-col -mt-4 md:mt-auto -mb-10 md:mb-auto ${
                showItemsFooterOne ? 'visible h-20' : 'hidden'
              } `}
            >
              <li>
                <a href="/airports">Airport</a>
              </li>
              <li>
                <a href="/city">City</a>
              </li>
              <li>
                <a href="/cruise">Cruise</a>
              </li>
            </ul>
          </div>
          <div>
            <div className="flex flex-row text-center">
              <p className="pt-2 lg:py-0 text-left text-sm font-bold md:text-left w-full md:max-w-xl">
                Get to know On Air Parking
              </p>
              <Button
                size="large"
                className="flex md:hidden border-none bg-transparent text-primary shadow-transparent hover:bg-transparent"
                onClick={() => setShowItemsFooterTwo(!showItemsFooterTwo)}
              >
                {showItemsFooterTwo ? <UpOutlined /> : <DownOutlined />}
              </Button>
            </div>
            <ul
              className={`flex flex-col -mt-4 md:mt-auto -mb-10 md:mb-auto ${
                showItemsFooterTwo ? 'visible h-36' : 'hidden'
              } `}
            >
              <li>
                <a href="/about">About us</a>
              </li>
              <li className="">
                <a href="/Sitemap.html">Site map</a>
              </li>
              <li className="">
                <a href="/privacypolicy">Privacy</a>
              </li>
              <li className="">
                <a href="/termsofservice">Terms of service</a>
              </li>
              <li className="">
                <a href="https://onairparking.com/blog">Blog</a>
              </li>
            </ul>
          </div>
          <div>
            <div className="flex flex-row text-center">
              <p className="pt-2 lg:py-0 text-left text-sm font-bold md:text-left w-full md:max-w-xl">
                Customer service
              </p>
              <Button
                size="large"
                className="flex md:hidden border-none bg-transparent text-primary shadow-transparent hover:bg-transparent"
                onClick={() => setShowItemsFooterThree(!showItemsFooterThree)}
              >
                {showItemsFooterThree ? <UpOutlined /> : <DownOutlined />}
              </Button>
            </div>
            <ul
              className={`flex flex-col -mt-4 md:mt-auto -mb-10 md:mb-auto ${
                showItemsFooterThree ? 'visible h-[6.5rem]' : 'hidden'
              } `}
            >
              <li>
                <a href="/contact">Contact us</a>
              </li>
              <li className="">
                <a href="/crypto">Cryptocurrencies</a>
              </li>
              <li className="">
                <a href="/reviews">Reviews</a>
              </li>
            </ul>
          </div>

          <div className=" items-start  ">
            <div className="flex flex-row text-center ">
              <p className="pt-2 lg:py-0 text-left text-sm font-bold md:text-left w-full md:max-w-xl">
                Partner
              </p>
              <Button
                size="large"
                className="flex md:hidden border-none bg-transparent text-primary shadow-none hover:bg-transparent "
                onClick={() => setShowItemsFooterFour(!showItemsFooterFour)}
              >
                {showItemsFooterFour ? <UpOutlined /> : <DownOutlined />}
              </Button>
            </div>
            <ul
              className={`flex flex-col -mt-4 md:mt-0  ${
                showItemsFooterFour ? 'visible' : 'hidden'
              } `}
            >
              <li>
                <a href="/partner">Partner with us</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center space-y-10 md:space-y-0 md:space-x-24 subFooter px-4 py-8 md:py-4">
          <p className="items-center">
            © Copyright On Air Parking {dayjs().format('YYYY')}
          </p>
          <div className="flex flex-col ">
            <a
              href="https://www.bbb.org/us/ca/westlake-village/profile/parking-facilities/on-air-parking-1236-92090148"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="hover:scale-110"
            >
              <img
                src="https://m.bbb.org/terminuscontent/dist/img/business-profile/accreditation/AB-seal-horz.svg?tx=f_svg,w_180"
                alt="BBB Rating"
                width={100}
                height={42}
              />
            </a>
          </div>
          <ButtonSocial />
          <div className="flex flex-row gap-x-4">
            <ButtonStore width={120} height={42} />
          </div>
        </div>
      </Footer>
    </div>
  );
}
